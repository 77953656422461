import React from 'react';
import { formatAmount } from './utils/FormatChange'
const PartnerCardList = ({ partnerData, handleRowClick }) => {
  const totals = partnerData.reduce((acc, partner) => {
    const available = Math.max(Number(partner.partnerData?.coinone_data?.available) || 0, 0);
    const withdrawAvailable = Math.max(Number(partner.partnerData?.coinone_data?.withdraw_available) || 0, 0);
    const availableAmount = Math.max(Number(partner.partnerData?.coinone_data?.withdraw_coin_amount) || 0, 0);
    return {
        totalAvailable: acc.totalAvailable + available,
        totalWithdrawAvailable: acc.totalWithdrawAvailable + withdrawAvailable,
        totalavailableAmount: acc.totalavailableAmount + availableAmount,
    };
}, {totalAvailable: 0, totalWithdrawAvailable: 0, totalavailableAmount: 0});

  return (
      <div className="partner-card-list">
        <div className="partner-card total-card">
          <h3>합계</h3>
          <p>보유원화: {formatAmount(totals.totalAvailable, 0)}</p>
          <p>사용가능금액: {formatAmount(totals.totalWithdrawAvailable, 0)}</p>
          <p>사용가능수량: {formatAmount(totals.totalavailableAmount, 0)}</p>
        </div>
        {partnerData.map((partner) => (
            <div key={partner.id} className="partner-card" onClick={() => handleRowClick(partner)}>
              <h3>{partner.username}</h3>
              <p>보유원화: {formatAmount(partner.partnerData?.coinone_data?.available, 0)}</p>
              <p>사용가능금액: {formatAmount(Math.max(partner.partnerData?.coinone_data?.withdraw_available, 0), 0)}</p>
              <p>시세: {formatAmount(partner.partnerData?.coinone_data?.last_price, 0)}</p>
              <p>사용가능수량: {formatAmount(Math.max(partner.partnerData?.coinone_data?.withdraw_coin_amount, 0), 0)}</p>
              <p>최근입금일자: {partner.partnerData?.coinone_data?.last_deposit}</p>
              <p>입금금액: {formatAmount(partner.partnerData?.coinone_data?.deposit_amount, 0)}</p>
              <p>기타: {partner.partner.remark ? '오류' : '-'}</p>
            </div>
        ))}
      </div>
  );
};

export default PartnerCardList;
