import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import useApiRequest from '../components/fetch/useApiRequest'; // API 요청 훅
import '../styles/PartnerDetail.css';
import { FaArrowLeft } from 'react-icons/fa';
import OrderList from "../components/OrderList";
import Loading from "../components/Loading";

const PartnerDetail = () => {
    const location = useLocation();
    const navigate = useNavigate(); // 이전 화면으로 돌아가기 위해 useNavigate 훅 사용
    // 이 코드는 React Router의 location 객체에서 partner 정보를 추출합니다.
    // location.state에 partner 정보가 있으면 그것을 사용하고, 없으면 빈 객체를 사용합니다.
    // 이렇게 하면 페이지 새로고침 시에도 partner 정보가 유지됩니다.
    const { partner } = location.state || {};
    const [orderAmount, setOrderAmount] = useState(''); // 주문 금액 상태
    const { postApiRequest } = useApiRequest();
    const token = localStorage.getItem('access_token');

    if (!partner) {
        return <div className="error-message">파트너 정보를 불러올 수 없습니다.</div>;
    }

    const handleOrderRequest = async () => {
        if (!orderAmount) {
            alert('주문 금액을 입력해주세요.');
            return;
        }

        try {
            await postApiRequest(
                '/admin/partner/order', // 주문 API 엔드포인트
                {
                    amount: orderAmount,
                    user_id: partner.id

                }, // 요청 본문에 금액 포함
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                (data) => {
                    alert(data.message);
                    setOrderAmount('')
                },
                (error) => {
                    alert(error);
                }
            );

        } catch (error) {
            console.error('주문 요청 중 오류가 발생했습니다:', error);
        }
    };

    const handleGoBack = () => {
        navigate(-1); // 이전 페이지로 이동
    };
    return (
        <>
            <div className="partner-detail-container">
                <div className="partner-detail-header">
                    <FaArrowLeft onClick={handleGoBack} className="back-icon" /> {/* 이전 화면으로 돌아가기 아이콘 */}
                    <h1 className="partner-detail-title">Partner Information</h1>
                </div>
                <div className="partner-name-header">
                    <h2 className="partner-name">{partner.username}</h2>
                </div>
                <div className="partner-detail-card">
                    <div className="partner-info">
                        <div className="partner-info-row">
                            <span>이메일:</span> <span>{partner.email}</span>
                        </div>
                        <div className="partner-info-row">
                            <span>추천코드:</span> <span>{partner.referral_code}</span>
                        </div>
                        <div className="partner-info-row">
                            <span>전화번호:</span> <span>{partner.partner?.phone_number}</span>
                        </div>
                        <div className="partner-info-row">
                            <span>은행:</span> <span>{partner.partner?.bank_name}</span>
                        </div>
                        <div className="partner-info-row">
                            <span>계좌번호:</span> <span>{partner.partner?.bank_account_number}</span>
                        </div>
                        <div className="partner-info-row">
                            <span>예금주:</span> <span>{partner.partner?.account_holder_name}</span>
                        </div>
                        <div className="partner-info-row full-width">
                            <span>오류 :</span> <span>{partner.partner?.remark}</span>
                        </div>
                    </div>
                </div>

                <h2 className="partner-detail-subtitle">코인원 정보</h2>
                <div className="partner-detail-card">
                    <div className="partner-info">
                        <div className="partner-info-row">
                            <span>보유원화:</span>
                            <span>{Math.floor(partner.partnerData?.coinone_data?.available).toLocaleString()} 원</span>
                        </div>
                        <div className="partner-info-row">
                            <span>사용가능금액:</span>
                            <span>{Math.floor(partner.partnerData?.coinone_data?.withdraw_available).toLocaleString()} 원</span>
                        </div>
                        <div className="partner-info-row">
                            <span>구매가능수량:</span>
                            <span>{Math.floor(partner.partnerData?.coinone_data?.withdraw_coin_amount).toLocaleString()} USDT</span>
                        </div>
                        <div className="partner-info-row">
                            <span>현재가격:</span>
                            <span>{Math.floor(partner.partnerData?.coinone_data?.last_price).toLocaleString()} 원</span>
                        </div>
                        {partner.partnerData?.error_message &&
                        <div className="partner-info-row full-width">
                            <span>오류:</span>
                            <span>{partner.partnerData?.error_message}</span>
                        </div>
                        }
                    </div>
                </div>
                <h2 className="partner-detail-subtitle">OKX 정보</h2>
                <div className="partner-detail-card">
                    <div className="partner-info">
                        <div className="partner-info-row full-width">
                            <span>OKX 입금주소:</span>
                            <span>{partner.partner?.okx_usdt_address}</span>
                        </div>
                    </div>
                </div>

                <h2 className="partner-detail-subtitle">주문</h2>

                <div className="partner-detail-form">
                    <input
                        type="number"
                        value={orderAmount}
                        onChange={(e) => setOrderAmount(e.target.value)}
                        placeholder="주문금액"
                    />
                    <button onClick={handleOrderRequest}>주문요청</button>
                </div>

                <h2 className="partner-detail-subtitle">주문목록</h2>
                {/* 주문 목록 표시 */}
                <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
                    <OrderList token={token} endpoint={`/admin/partner/detail/${partner.id}/orders`} userBool={false} />
                </div>
            </div>
        </>
    );
};

export default PartnerDetail;
