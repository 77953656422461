import React, {useCallback, useEffect, useState, useContext} from 'react';
import useApiRequest from '../components/fetch/useApiRequest'; // API 요청 훅
import OrderTable from './OrderTable';
import OrderCard from './OrderCard';
import useMediaQuery from './customhook/useMediaQuery';
import '../styles/OrderList.css'; // 테이블 스타일링을 위한 CSS 불러오기
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../UserContext';
import Loading from './Loading';

const OrderList = ({token, endpoint, userBool}) => {
    const {getApiRequest, loading} = useApiRequest();
    const [orders, setOrders] = useState([]);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const navigate = useNavigate();
    const {user} = useContext(UserContext);
    const handleDetailView = (orderId) => {
        if (user.id === 1) {
            navigate(`/admin/order/${orderId}`); // 주문 상세 페이지로 이동
        } else {
            navigate(`/order/${orderId}`); // 주문 상세 페이지로 이동
        }
    };

    const fetchOrders = useCallback(async () => {
        try {
            await getApiRequest(
                endpoint, // 주문 목록을 가져올 API 엔드포인트
                {},
                {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
            (data) =>
            {
                setOrders(data);
            }
        ,
            (errorMessage) => {
                console.log(errorMessage);
            }
            );
        } catch (error) {
            console.error('주문 목록 요청 중 오류가 발생했습니다:', error);
            // eslint-disable-next-line
        }}, [token, endpoint]);

    useEffect(() => {
        fetchOrders();
        const intervalId = setInterval(() => {
            fetchOrders();
        }, 5000);

        return () => clearInterval(intervalId); // 컴포넌트 언마운트 시 타이머 정리
    // eslint-disable-next-line
    }, [token]); // 의존성 배열에 token과 endpoint 추가

    return (
        <div className="order-list-container">
            {
                isMobile ? (
                    <OrderCard orders={orders} onDetailView={handleDetailView} user={userBool}/>
                ) : (
                    <OrderTable orders={orders} onDetailView={handleDetailView} user={userBool}/>
                )
            }
        </div>
    );
};

export default OrderList;
