const FormatChange = () => {
    const formatAmount = (amount, fixed = 0) => {
        if (amount === null || amount === undefined || amount === '' || isNaN(amount)) {
            return 0;  // 찾을 수 없는 값 처리
        }
        if (typeof amount === 'string') {
            amount = parseFloat(amount);
        }

        if (fixed === 0) {
            // 소수점 없이 표현
            let integerPart = Math.floor(amount).toString();
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `${integerPart}`;
        } else {
            // 소수점 fixed 만큼 표현
            let [integerPart, decimalPart] = amount.toFixed(fixed).split('.');
            integerPart = integerPart.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
            return `${integerPart}.${decimalPart}`;
        }
    };

    const formatDate = (dateString) => {
        const date = new Date(dateString);
        if (isNaN(date.getTime())) {
            return 'Invalid date';  // 잘못된 날짜 처리
        }
        return new Intl.DateTimeFormat('ko-KR', {
            year: 'numeric',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            minute: '2-digit',
            hour12: false
        }).format(date);
    };

    return {formatAmount, formatDate};
};

export const {formatAmount, formatDate} = FormatChange();
