import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import useApiRequest from '../components/fetch/useApiRequest';
import PartnerTable from '../components/PartnerTable';
import PartnerCardList from '../components/PartnerCardList';
import useMediaQuery from '../components/customhook/useMediaQuery';
import '../styles/PartnerList.css';

const PartnerList = () => {
    const { getApiRequest, postApiRequest, loading } = useApiRequest();
    const [partnerData, setPartnerData] = useState([]);
    const isMobile = useMediaQuery('(max-width: 768px)');
    const token = localStorage.getItem('access_token');
    const navigate = useNavigate();

    const getPartners = useCallback(async () => {
        await getApiRequest(
            '/admin/partner/rangking',
            {},
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            (data) => {
                setPartnerData(data);
            },
            (errorMessage) => {
                console.log(errorMessage);
            }
        );
        // eslint-disable-next-line
    }, [token]);

    useEffect(() => {
        getPartners();
        // eslint-disable-next-line
    }, []);

    const handleRowClick = (partner) => {
        navigate(`/admin/partner/${partner.id}`, { state: { partner } });
    };

    const handleBalanceClick = async () => {
        await postApiRequest(
            '/admin/balance',
            {},
            {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`
            },
            (data) => {
                console.log('Balance data:', data);
            },
            (errorMessage) => {
                console.log(errorMessage);
            }
        );
    };

    return (
        <div className="partner-list-container">
            <h1 className="partner-list-title">Partner Information</h1>
            <div className='partner-button'>
                <button onClick={handleBalanceClick} disabled={loading}>
                    {loading ? '로딩 중...' : 'OKX API'}
                </button>
            </div>
            {isMobile ? (
                <PartnerCardList partnerData={partnerData} handleRowClick={handleRowClick} />
            ) : (
                <PartnerTable partnerData={partnerData} handleRowClick={handleRowClick} />
            )}
        </div>
    );
};

export default PartnerList;
