import OrderList from '../components/OrderList';
import OrderRequest from '../components/admincomponents/OrderRequest'
import '../styles/UserDetail.css';
import useUserData from '../components/customhook/useUserData'
import { useCallback, useEffect, useState } from 'react';

const UserDetails = () => {
  const token = localStorage.getItem('access_token');
  const [user, setUserdata] = useState()
  const [loading, setLoading] = useState(true);
  const [address, setAddress] = useState(''); // 주소 상태 추가
  
  const { getuserdata } = useUserData();

  

  const copyToClipboard = async (text) => {
    try {
      const referral_link = `https://partners.bonegowallet.com/partner-signup?referral=${text}`
      await navigator.clipboard.writeText(referral_link);
      alert('파트너 회원가입 추천링크 복사됨!');
    } catch (err) {
      alert('복사 실패');
    }
  };

  const bonegoWalletCopyToClipboard = async (text) => {
    try {
      const referral_link = `https://exchange.bonegowallet.com?referral=${text}`
      await navigator.clipboard.writeText(referral_link);
      alert('보내고 월렛 추천링크 복사됨!');
    } catch (err) {
      alert('복사 실패');
    }
  };

  const getUser = useCallback(async () => {
    const response = await getuserdata(token);
    if (response) {
      setUserdata(response.data);
      setLoading(false)
    }
    // eslint-disable-next-line
  }, [token]); // `getuserdata`와 `token`에 의존

  useEffect(() => {
    getUser();
    // eslint-disable-next-line
  }, []);

  if (!user) return <div>Loading...</div>
  if (loading) return <div>Loading...</div>

  return (
    <>
      <div className="partner-detail-container">
        <div className="partner-detail-header">
          <h1 className="partner-detail-title">User Information</h1>
        </div>
        <div className="partner-name-header">
          <h1 className="partner-name">{user.username}</h1>
        </div>
        <div className="partner-detail-card">
          <div className="partner-info">
            <div className="partner-info-row">
              <span>이메일:</span> <span>{user.email}</span>
            </div>
            <div className="partner-info-row" onClick={() => copyToClipboard(user.referral_code)}
              style={{ cursor: 'pointer' }}>
              <span>파트너 추천코드(클릭시 복사):</span> <span>{user.referral_code}</span>
            </div>
            <div className="partner-info-row">
              <span>전화번호:</span> <span>{user.partner?.phone_number}</span>
            </div>
            <div className="partner-info-row">
              <span>은행:</span> <span>{user.partner?.bank_name}</span>
            </div>
            <div className="partner-info-row">
              <span>계좌번호:</span> <span>{user.partner?.bank_account_number}</span>
            </div>
            <div className="partner-info-row">
              <span>예금주:</span> <span>{user.partner?.account_holder_name}</span>
            </div>
            <div className="partner-info-row full-width" onClick={() => bonegoWalletCopyToClipboard(user.referral_code)}
              style={{ cursor: 'pointer' }}>
              <span>보내고월렛 추천링크(클릭시 복사):</span> <span>https://exchange.bonegowallet.com?referral={user.referral_code}</span>
            </div>
          </div>
        </div>
        <h2 className="partner-detail-subtitle">코인원 정보</h2>
        <div className="partner-detail-card">
          <div className="partner-info">
            <div className="partner-info-row full-width">
              <span>API 키:</span>
              <span>{user.partner?.coinone_api_key}</span>
            </div>
          </div>
        </div>
        <h2 className="partner-detail-subtitle">OKX 정보</h2>
        <div className="partner-detail-card">
          <div className="partner-info">
            <div className="partner-info-row full-width">
              <span>API 키:</span>
              <span>{user.partner?.okx_api_key}</span>
            </div>
            <div className="partner-info-row full-width">
              <span>USDT 입금주소:</span>
              <span>{user.partner?.okx_usdt_address}</span>
            </div>
          </div>
        </div>

        {user.user_role === 'admin' ? ( // 유저 역할이 admin일 때만 주문 섹션 표시
          <OrderRequest 
            address={address}
            setAddress={setAddress}
            token={token}
            user={user}
          />
        ) : (
          <>
            <h2 className="partner-detail-subtitle">주문목록</h2>
            <div style={{ maxHeight: '300px', overflowY: 'auto' }}>
              <OrderList token={token} endpoint={`/orders`} userBool={false} />
            </div>
          </>
        )
        }
      </div>
    </>
  );
};

export default UserDetails;